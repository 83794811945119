import React, { useContext, useState } from "react";
import { Navbar, Nav, Container, Offcanvas, Form } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Logo } from "src/components/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Dropdown } from "react-bootstrap";
// Menu Icons Dark Mode
import iconDashboardLight from "../../../assets/images/menu-icons/icon-dashboard-light.svg";
import iconHotFireLight from "../../../assets/images/menu-icons/icon-hot-fire-light.svg";
import iconNewsLight from "../../../assets/images/menu-icons/icon-news-light.svg";
import iconLiveFlowLight from "../../../assets/images/menu-icons/icon-bar-graph-light.svg";
import iconTickerLight from "../../../assets/images/menu-icons/icon-ticket-light.svg";
import iconFilterLight from "../../../assets/images/menu-icons/icon-filter-sliders-light.svg";
import iconAlertLight from "../../../assets/images/menu-icons/icon-bell-light.svg";
import iconDarkPoolLight from "../../../assets/images/menu-icons/icon-trend-tag-light.svg";
import iconSettingLight from "../../../assets/images/menu-icons/icon-cog-light.svg";
import iconLogoutLight from "../../../assets/images/menu-icons/icon-logout-light.svg";
import iconResourceDark from "../../../assets/images/menu-icons/icon-resource-dark.svg";

//Menu Icons Light Mode
import iconDashboardPrimary from "../../../assets/images/menu-icons/icon-dashboard-primary.svg";
import iconHotFirePrimary from "../../../assets/images/menu-icons/icon-hot-fire-primary.svg";
import iconNewsPrimary from "../../../assets/images/menu-icons/icon-news-primary.svg";
import iconLiveFlowPrimary from "../../../assets/images/menu-icons/icon-bar-graph-primary.svg";
import iconTickerPrimary from "../../../assets/images/menu-icons/icon-ticket-primary.svg";
import iconFilterPrimary from "../../../assets/images/menu-icons/icon-filter-sliders-primary.svg";
import iconAlertPrimary from "../../../assets/images/menu-icons/icon-bell-primary.svg";
import iconDarkPoolPrimary from "../../../assets/images/menu-icons/icon-trend-tag-primary.svg";
import iconSettingPrimary from "../../../assets/images/menu-icons/icon-cog-primary.svg";
import iconLogoutPrimary from "../../../assets/images/menu-icons/icon-logout-primary.svg";
import iconResourceLight from "../../../assets/images/menu-icons/icon-resource-light.svg";

import { ThemeDetailsContext } from "../../../context/ThemeContext";
import { useAuth } from "src/context/AuthContext";
import { Alert } from "@mui/material";
import hasAccess from "src/utils/guard";
import { SUBSCRIPTION_PLANS } from "src/constant";
// import { l } from 'src/services/authService';
const MobileMenu = () => {
  const navigate = useNavigate();
  //const auth = useAuth()
  const { user, logout } = useAuth();
  const isPremiumUser = hasAccess([SUBSCRIPTION_PLANS.PREMIUM], user)

  const handelCahnge = (value) => {
    if (value == "logout") {
      console.log("Its called logout");
      //auth.logout()
      window.location.reload();
    }
  };
  let expand = "false";
  const getName = () => {
    // let firstAndLast = userfront?.user.name.split(" ")
    let firstAndLast = `${user?.name}${" "}`;
    return `${firstAndLast[0].charAt(0).toUpperCase()}${firstAndLast[1]
      .charAt(0)
      .toUpperCase()}`;
  };
  const { theme, setTheme } = useContext(ThemeDetailsContext);
  const handelClose = (e, link) => {
    e.preventDefault();
    let x = window.document
      .getElementById("menuClose")
      .getElementsByClassName("btn-close")[0]
      .click();
    navigate(link);
  };
  const handleExternalLink = (link)=>{
    window.open(link, '_blank');
  }
  return (
    <Navbar
      key={expand}
      bg="transparent"
      expand={expand}
      className="header-dashboard d-lg-none"
    >
      <Container fluid>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Navbar.Brand to="#">
          <Logo />
        </Navbar.Brand>
        {/* <h3 className='d-inline-flex justify-content-center align-items-center fw-semibold fs-5 m-0 rounded-circle h-40px w-40px border border-gray-600'></h3> */}
        <Dropdown
          align={"end"}
          className="d-inline-flex justify-content-center align-items-center fw-semibold fs-5 m-0 rounded-circle h-40px w-40px border border-gray-600"
        >
          <Dropdown.Toggle
            variant="default"
            className="py-1 btn-flush mobile-user-dropdown-toggle"
          >
            {getName()}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => navigate("/my-account")}>
              My Account
            </Dropdown.Item>
            <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="start"
          className={`${theme == "dark" ? "bg-color" : "bg-lite"}`}
        >
          <Offcanvas.Header closeButton id="menuClose" className="dashboard">
            {/* <Form className="custom-swich">
              <Form.Check
                type="switch"
                id="cheked-custom-switch"
                label=""
                checked={theme == "dark" ? true : false}
                onChange={(e) => {
                  setTheme(theme == "dark" ? "light" : "dark")
                }}
              />
            </Form> */}
            <div className="theme-toggle">
              <div className="form-check form-switch form-check-custom form-check-solid">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="themeToggle"
                  role="switch"
                  checked={theme == "dark" ? true : false}
                  onChange={() => { }}
                  onClick={() => {
                    setTheme(theme == "dark" ? "light" : "dark");
                  }}
                />
                {/* <label htmlFor="themeToggle" className="form-check-label">
                    {theme == "dark"
                      ? "Switch to light mode"
                      : "Switch to dark mode"}
                  </label> */}
                {/* <Tooltip className="nav-tooltip" id="menu-toggletheme" /> */}
              </div>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="nav-mobile flex flex-column">
              <Link
                onClick={(e) => handelClose(e, "/dashboard")}
                className="nav-link"
              >
                <img
                  src={
                    theme == "light" ? iconDashboardPrimary : iconDashboardLight
                  }
                  alt=""
                  className="link-icon"
                ></img>
                <span className="link-text">Dashboard</span>
              </Link>
              <Link
                className="nav-link"
                onClick={(e) => handelClose(e, "/top-gainer-loser")}
              >
                <img
                  src={theme == "light" ? iconTickerPrimary : iconTickerLight}
                  alt=""
                  className="link-icon"
                ></img>
                <span className="link-text">Top Gainer/Losers</span>
              </Link>
              <Link
                onClick={(e) => handelClose(e, "/hottest-contracts")}
                className="nav-link"
              >
                <img
                  src={theme == "light" ? iconHotFirePrimary : iconHotFireLight}
                  alt=""
                  className="link-icon"
                ></img>
                <span className="link-text">Hottest Contracts/Chains</span>
              </Link>
              {/* <Link
                onClick={(e) => handelClose(e, "/dark-pool")}
                className="nav-link"
              >
                <img
                  src={
                    theme == "light" ? iconDarkPoolPrimary : iconDarkPoolLight
                  }
                  alt=""
                  className="link-icon"
                ></img>
                <span className="link-text">Dark Pool</span>
              </Link> */}
              <Link
                onClick={(e) => handelClose(e, "/unusual-flow-picks")}
                className="nav-link"
              >
                <img
                  src={
                    theme == "light" ? iconLiveFlowPrimary : iconLiveFlowLight
                  }
                  alt=""
                  className="link-icon"
                ></img>
                <span className="link-text">Unusual Flow Picks</span>
              </Link>
              <Link
                onClick={(e) => handelClose(e, "/tickers")}
                className="nav-link"
              >
                <img
                  src={theme == "light" ? iconTickerPrimary : iconTickerLight}
                  alt=""
                  className="link-icon"
                ></img>
                <span className="link-text">Tickers</span>
              </Link>
              <Link
                onClick={(e) => handelClose(e, "/bookmark")}
                className="nav-link"
              >
                <FontAwesomeIcon
                  fontSize={16}
                  icon={icon({ name: "star" })}
                  color={theme === "light" ? "#E4A21E" : "white"}
                  className="link-icon"
                />
                {/* <img src={theme == "light" ? iconLiveFlowPrimary : iconLiveFlowLight} alt="" className="link-icon"></img> */}
                <span className="link-text">Bookmarks</span>
              </Link>
              <Link
                onClick={(e) => handelClose(e, "/news")}
                className="nav-link"
              >
                <img
                  src={theme == "light" ? iconNewsPrimary : iconNewsLight}
                  alt=""
                  className="link-icon"
                ></img>
                <span className="link-text">News</span>
              </Link>
              <Link
                onClick={() => handleExternalLink("https://docs.unusualflow.com/en/")}
                className="nav-link"
              >
                <img
                  src={ theme == "light" ? iconResourceDark : iconResourceLight}
                  alt=""
                  className="link-icon"
                ></img>
                <span className="link-text">Resource</span>
              </Link>
              {/* <Link onClick={(e) => handelClose(e, "/alerts")} className="nav-link">
                <img src={theme == "light" ? iconAlertPrimary : iconAlertLight} alt="" className="link-icon"></img>
                <span className='link-text'>Alerts</span>
              </Link> */}
              {/* <div className="mt-10 pt-10 border-top border-gray-200">
                <Link onClick={(e) => handelClose(e, "/my-account")} className="nav-link">
                  <img src={theme == "light" ? iconSettingPrimary : iconSettingLight} alt="" className="link-icon"></img>
                    <span className='link-text'>My Account</span>
                </Link>
                <Link className="nav-link" onClick={() => logout()}>
                  <img src={theme == "light" ? iconLogoutPrimary : iconLogoutLight} alt="" className="link-icon"></img>
                  <span className='link-text'>Log Out</span>
                </Link>
              </div> */}
            </Nav>
           {isPremiumUser &&  <Alert severity="info" className="mt-5">
              <div className="premium-banner">
                You are subscribed to a 15-minute delayed feed for all data,
                prices and aggregate.{" "}
                <NavLink to={"/pricing"}> Upgrade</NavLink> your account to
                access real-time data, prices and aggregate.
              </div>
            </Alert>}
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default MobileMenu;

